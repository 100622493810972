import {
  carbonMobile,
  deliveryMobile,
  growerMobile,
  wrappingMobile,
} from '@/assets';
import {useTranslations} from 'next-intl';
import Image from 'next/image';
import React from 'react';

const ProductSRFeaturesMobile = () => {
  const t = useTranslations();
  return (
    <div className="flex bg-lightPurple px-6 pb-4">
      <div className="flex flex-col items-center gap-4">
        <Image
          src={deliveryMobile}
          alt="Delivery Express Mobile"
          width={73}
          height={73}
        />
        <h6 className="text-center font-secondary text-[12px] font-thin text-persianIndigo">
          {t('express_delivery')}
        </h6>
      </div>
      <div className="flex flex-col items-center gap-4">
        <Image
          src={wrappingMobile}
          alt="Gift Wrapping Mobile"
          width={73}
          height={73}
        />
        <h6 className="text-center font-secondary text-[12px] font-thin text-persianIndigo">
          {t('complimentary_gift_wrapping')}
        </h6>
      </div>
      <div className="flex flex-col items-center gap-4">
        <Image
          src={growerMobile}
          alt="Direct From Grower Mobile"
          width={73}
          height={73}
        />
        <h6 className="text-center font-secondary text-[12px] font-thin text-persianIndigo">
          {t('direct_from_the_grower')}
        </h6>
      </div>
      <div className="flex flex-col items-center gap-4">
        <Image
          src={carbonMobile}
          alt="100% Carbon Mobile"
          width={73}
          height={73}
        />
        <h6 className="text-center font-secondary text-[12px] font-thin text-persianIndigo">
          {t('carbon_neutral')}
        </h6>
      </div>
    </div>
  );
};

export default ProductSRFeaturesMobile;
