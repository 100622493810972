import {carbon, expressDelivery, giftWrapping, grower} from '@/assets';
import {useTranslations} from 'next-intl';
import Image from 'next/image';
import React from 'react';

const ProductSRFeatures = () => {
  const t = useTranslations();
  return (
    <div className="me-6 mt-6 rounded-[11px] bg-lightPurple px-4 py-6">
      <div>
        <div className="mb-6 flex items-center gap-6">
          <div>
            <Image
              src={expressDelivery}
              alt="express delivery"
              height={70}
              width={50}
            />
          </div>

          <div>
            <h6 className="font-secondary text-[16px] font-semibold text-persianIndigo">
              {t('express_delivery')}
            </h6>
            <p className="font-secondary text-[12px] font-thin text-persianIndigo">
              {t('around_180_minutes')}
            </p>
          </div>
        </div>
        <div className="mb-6 flex items-center gap-6">
          <div>
            <Image
              src={giftWrapping}
              alt="Gift Wrapping"
              height={70}
              width={50}
            />
          </div>
          <div>
            <h6 className="font-secondary text-[16px] font-semibold text-persianIndigo">
              {t('no_cost_gift_wrapping')}
            </h6>
            <p className="font-secondary text-[12px] font-thin text-persianIndigo">
              {t('complimentary_gift_wrapping')}
            </p>
          </div>
        </div>
        <div className="mb-6 flex items-center gap-6">
          <div>
            <Image src={grower} alt="Premium Flowers" height={70} width={50} />
          </div>
          <div>
            <h6 className="font-secondary text-[16px] font-semibold text-persianIndigo">
              {t('premium_flowers')}
            </h6>
            <p className="font-secondary text-[12px] font-thin text-persianIndigo">
              {t('direct_from_the_grower')}
            </p>
          </div>
        </div>
        <div className="mb-6 flex items-center gap-6">
          <div>
            <Image src={carbon} alt="Carbon Neutral" height={70} width={50} />
          </div>
          <div>
            <h6 className="font-secondary text-[16px] font-semibold text-persianIndigo">
              {t('zero_carbon_footprint')}
            </h6>
            <p className="font-secondary text-[12px] font-thin text-persianIndigo">
              {t('carbon_neutral')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSRFeatures;
