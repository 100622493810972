'use client';

import {share} from '@/assets';
import Image from 'next/image';
import React, {useMemo, useState} from 'react';
import {CustomButton} from '../common';
import {showErrorToast, showSuccessToast} from '@/common';
import {useLocale, useTranslations} from 'next-intl';
import {usePathname} from '@/utils/navigation';
import {GoHeart, GoHeartFill} from 'react-icons/go';
import {UseAddOrRemoveFav} from '@/hooks';
import {BsArrowsFullscreen} from 'react-icons/bs';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@heroui/react';

const ProductActions = ({selectedVariant, product, selectedImage}) => {
  const t = useTranslations();
  const pathName = usePathname();
  const [isOpen, setIsOpen] = useState(false);

  const locale = useLocale();
  const {
    applyOrRemoveFavItem,
    localFavData,
    isFavProductsAreLoading,
    favToken,
  } = UseAddOrRemoveFav();

  const isProductInFavorites = useMemo(() => {
    return localFavData?.find(
      item => item?.product.id === product?.productInfo?.id,
    );
  }, [localFavData, product?.productInfo?.id]);

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(
        `${process.env.NEXT_PUBLIC_FRONT_END_SERVER}/${locale}${pathName}`,
      )
      .then(
        () => {
          showSuccessToast(t('product_link_copied_to_clipboard'));
        },
        err => {
          showErrorToast(t('failed_to_copy_product_link'));
        },
      );
  };
  const handleAddOrRemoveFromFav = () => {
    applyOrRemoveFavItem(
      isProductInFavorites,
      selectedVariant?.id,
      product?.productInfo?.id,
    );
  };

  return (
    <>
      <div className="absolute top-[16px] flex flex-col gap-[14rem] ltr:right-[40px] ltr:sm:right-[16px] rtl:left-[40px] rtl:sm:left-[16px]">
        <div className="flex flex-col gap-4">
          <CustomButton
            iconOnly
            onClickHandling={handleCopyLink}
            btnStyles="bg-white !p-1 !rounded-sm cursor-pointer"
            value={
              <Image
                src={share}
                width={50}
                height={50}
                className="h-[20px] w-[20px]"
                alt="share"
              />
            }
          />
          {favToken && (
            <CustomButton
              iconOnly
              isLoading={isFavProductsAreLoading}
              onClickHandling={handleAddOrRemoveFromFav}
              btnStyles="bg-white !p-1 !rounded-sm cursor-pointer"
              value={
                isProductInFavorites ? (
                  <GoHeartFill className="text-[22px] font-bold text-persianIndigo" />
                ) : (
                  <GoHeart className="text-[22px] font-bold text-persianIndigo" />
                )
              }
            />
          )}
        </div>
        <CustomButton
          iconOnly
          onClickHandling={() => setIsOpen(true)}
          btnStyles="bg-white !p-1 !rounded-sm cursor-pointer"
          value={
            <BsArrowsFullscreen className="text-[22px] font-bold text-persianIndigo" />
          }
        />
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        classNames={{
          closeButton: 'hidden',
        }}
        size="full"
        backdrop="blur">
        <ModalContent>
          <ModalHeader className="flex items-center justify-between">
            <span className="font-secondary text-persianIndigo">
              {product?.productInfo.name}
            </span>
            <Button isIconOnly variant="light" onPress={() => setIsOpen(false)}>
              ✖
            </Button>
          </ModalHeader>
          <ModalBody className="flex items-center justify-center">
            <Image
              width={500}
              height={500}
              src={selectedImage}
              alt="Flower Bouquet"
              className="max-h-screen max-w-full object-contain"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProductActions;
