'use client';

import {
  commonDisableCachingParameters,
  convertEnglishNumbersToArabic,
  showErrorToast,
} from '@/common';
import useEmblaCarousel from 'embla-carousel-react';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import React, {useEffect, useMemo, useState} from 'react';
import {
  AddOns,
  GiftCard,
  ProductSRFeaturesMobile,
  RecentlyViewed,
  RelatedProducts,
  WaysToPay,
} from '.';
import {
  useGetAddOnsQuery,
  useGetAllCategoriesQuery,
  useGetCardDesignsQuery,
  useGetSuggestedProductsQuery,
} from '@/services';
import {getCart, getNearestStoreData, getToken, getUserData} from '@/selectors';
import {useDispatch, useSelector} from 'react-redux';
import {Tab, Tabs} from '@heroui/react';
import {sendGTMEvent} from '@next/third-parties/google';
import {addProduct} from '@/slices';
import {CustomButton} from '../common';
import {useApplyCartActions} from '@/hooks';

const MobileProductScreen = ({product}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [writtenCardData, setWrittenCardData] = useState(null);
  const [chosenGiftCard, setChoosenGiftCard] = useState();
  const [selectedAddons, setSelectedAddons] = useState(null);
  const [isGiftCardShown, setIsGiftCardShown] = useState(false);
  const [selectedTab, setSelectedTab] = useState('productDetails');
  const store = useSelector(getNearestStoreData);
  const dispatch = useDispatch();
  const cart = useSelector(getCart);
  const userData = useSelector(getUserData);
  const token = useSelector(getToken);
  const {applyCartAction, isAddingToCartLoading} = useApplyCartActions();

  const t = useTranslations();
  const locale = useLocale();

  const {data: cardDesigns, error: getCardDesignsError} =
    useGetCardDesignsQuery(
      {locale, 'filter[branches]': store?.id},
      commonDisableCachingParameters,
    );

  const {data: allCategories, isError: getAllCategoriesError} =
    useGetAllCategoriesQuery(
      {include: 'image', locale, per_page: 100},
      commonDisableCachingParameters,
    );

  const addonsCategory = useMemo(() => {
    return allCategories?.data?.find(category => category.attributes.addons);
  }, [allCategories]);

  const productLineItem = useMemo(() => {
    return cart?.lineItems?.find(
      item => item?.name === product?.productInfo?.name,
    );
  }, [cart, product]);

  const {data: addOns, error: getAddOnsError} = useGetAddOnsQuery(
    {
      locale,
      'filter[branches]': store?.id,
      per_page: 100,
      'filter[taxons]': addonsCategory?.id,
    },
    commonDisableCachingParameters,
  );
  const {
    data: relatedProducts,
    isLoading: isGettingSuggestedProductsLoading,
    error: getSuggestedProductsError,
  } = useGetSuggestedProductsQuery(
    {
      params: {
        include: 'images',
        locale,
        'filter[taxons]': product?.taxonInfo?.[0]?.id,
        'filter[id_not_eq]': product?.productInfo?.id,
        'filter[branches]': store?.id,
      },
    },
    commonDisableCachingParameters,
  );

  const handleAddingToCart = async () => {
    try {
      const productAndGiftCardPromises = [];

      if (selectedVariant?.id) {
        productAndGiftCardPromises.push(
          applyCartAction('add', selectedVariant, 1),
        );
      }

      if (
        chosenGiftCard?.id &&
        !cart?.hasWrittenCard &&
        writtenCardData?.get('message') &&
        isGiftCardShown
      ) {
        productAndGiftCardPromises.push(
          applyCartAction('add', chosenGiftCard, 1),
        );
      }

      await Promise.all(productAndGiftCardPromises);

      if (selectedAddons?.length > 0) {
        await selectedAddons.reduce(async (previousPromise, addon) => {
          await previousPromise;
          await applyCartAction('add', addon, 1);
        }, Promise.resolve());
      }
    } catch (error) {
      showErrorToast(t('failed_to_add_items_to_cart'));
    }

    if (writtenCardData?.get('message')) {
      const requestOptions = {
        method: 'POST',
        body: writtenCardData,
        redirect: 'follow',
      };

      fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/api/v2/storefront/written_cards`,
        requestOptions,
      )
        .then(response => response.text())
        .catch(error => showErrorToast(t('video_upload_failed')));
    }
  };

  const onHandlingChoosenGiftCard = cardId => {
    setChoosenGiftCard(cardId);
  };

  const onHandlingSelectedAddons = addons => {
    setSelectedAddons(addons);
  };

  useEffect(() => {
    setSelectedVariant(product?.productVariants?.[0]);
  }, [product]);

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      setSelectedIndex(emblaApi.selectedScrollSnap());
    };

    emblaApi.on('select', onSelect);
    onSelect();
  }, [emblaApi]);

  useEffect(() => {
    if (product) {
      dispatch(addProduct(product?.productInfo));
    }
  }, [product, dispatch]);

  useEffect(() => {
    if (product?.productInfo?.name) {
      sendGTMEvent({
        event: 'view_product_trigger',
        product_name: product?.productInfo.name,
        product_price: product?.productInfo.price,
        product_currency: 'SAR',
        product_quantity: productLineItem?.quantity || 0,
        product_value: productLineItem?.total || 0,
        ...(token &&
          userData?.userName && {
            name: userData.userName,
            email: userData.userEmail,
            phone_number: userData.userPhone,
          }),
      });
    }
  }, [product, token, userData]);

  return (
    <div>
      <div className="relative">
        <div className="embla w-full overflow-hidden" ref={emblaRef}>
          <div className="flex">
            {selectedVariant?.attributes?.images.map((image, index) => (
              <div className="flex-[0_0_100%] cursor-pointer" key={index}>
                <div className="flex h-[500px] items-center justify-center">
                  <Image
                    src={image}
                    width={500}
                    height={500}
                    sizes="100vw"
                    className="h-full w-full object-cover"
                    alt={`Product image ${index}`}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="absolute bottom-[40px] left-[47%] flex justify-center gap-2">
          {selectedVariant?.attributes?.images.map((_, index) => (
            <div
              key={index}
              className={`h-3 w-3 rounded-full transition-all ${
                selectedIndex === index ? 'bg-persianIndigo' : 'bg-white'
              }`}
            />
          ))}
        </div>
      </div>
      <div className="my-4 px-6">
        <p className="font-main text-[24px] font-medium text-persianIndigo">
          {convertEnglishNumbersToArabic(
            Number(product?.productInfo?.price),
            locale,
          )}{' '}
          {t('sar')}{' '}
        </p>
        <p className="font-regular font-secondary text-[12px] text-persianIndigo">
          {t('price_include_tax')}
        </p>
        <p className="my-4 font-secondary font-thin text-persianIndigo">
          {product?.productInfo?.name}
        </p>
        <WaysToPay />
      </div>
      <div className="bg-mediumWhite">
        <GiftCard
          setWrittenCardData={setWrittenCardData}
          onHandlingChoosenGiftCard={onHandlingChoosenGiftCard}
          cardDesigns={cardDesigns}
          isGiftCardShown={isGiftCardShown}
          setIsGiftCardShown={setIsGiftCardShown}
        />
      </div>
      <div>
        <AddOns
          addOns={addOns}
          t={t}
          onHandlingSelectedAddons={onHandlingSelectedAddons}
        />
      </div>
      <div className="bg-lightPurple px-6">
        <Tabs
          aria-label="Options"
          variant="underlined"
          selectedKey={selectedTab}
          fullWidth
          onSelectionChange={setSelectedTab}
          classNames={{
            tabContent:
              'text-[13px] md:text-[16px]  group-data-[selected=true]:text-persianIndigo font-secondary font-normal text-persianIndigo',
            tabList: 'gap-0 border-b-1 border-thistle pb-[15px]',
            cursor: 'w-[102%] bottom-[-15px] bg-persianIndigo',
          }}>
          <Tab key="productDetails" title={t('product_details')}>
            <div
              className={`transition-max-height max-h-screen overflow-hidden duration-500 ease-in-out`}>
              <div
                className={`${product?.productInfo?.productDesc ? '' : 'text-center'} max-h-[40vh] overflow-y-auto break-words p-4`}>
                {product?.productInfo?.productDesc ? (
                  <div
                    className="text-persianIndigo sm:text-[14px] xl:text-[16px]"
                    dangerouslySetInnerHTML={{
                      __html: product?.productInfo?.productDesc,
                    }}
                  />
                ) : (
                  <span className="text-persianIndigo sm:text-[14px] xl:text-[1vw]">
                    {t('no_product_details_available')}
                  </span>
                )}
              </div>
            </div>
          </Tab>
          {product?.productInfo?.productCareTips && (
            <Tab key="careTips" title={t('care_tips')}>
              <div className="max-h-[40vh] overflow-y-auto break-words p-4">
                <div
                  className="text-persianIndigo sm:text-[14px] xl:text-[16px]"
                  dangerouslySetInnerHTML={{
                    __html: product?.productInfo?.productCareTips,
                  }}
                />
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
      <ProductSRFeaturesMobile />
      <RelatedProducts
        t={t}
        locale={locale}
        products={relatedProducts}
        category={product?.taxonInfo?.[0]?.permaLink}
      />
      <RecentlyViewed t={t} locale={locale} />
      <div className="fixed bottom-0 w-full justify-end bg-white px-6 py-3 sm:static sm:flex sm:px-0 sm:pr-6 xl:pr-0">
        <CustomButton
          value={t('add_to_cart')}
          isLoading={isAddingToCartLoading}
          onClickHandling={handleAddingToCart}
          btnStyles="w-full h-[3.313rem] bg-persianIndigo font-secondary font-thin"
        />
      </div>
    </div>
  );
};

export default MobileProductScreen;
