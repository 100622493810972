'use client';

import useEmblaCarousel from 'embla-carousel-react';
import Image from 'next/image';
import {convertEnglishNumbersToArabic, renderStars} from '@/common/helpers';
import {useSelector} from 'react-redux';
import {getNearestStoreData, getRecentlyViewedProducts} from '@/selectors';
import {product2} from '@/assets';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import {CustomButton} from '../common';
import {useMediaQuery} from 'react-responsive';
import {useEffect, useState} from 'react';
import Link from 'next/link';

const RecentlyViewed = ({t, locale}) => {
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});
  const [emblaRef, emblaApi] = useEmblaCarousel({loop: false});
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const recentlyViewedProducts = useSelector(getRecentlyViewedProducts);
  const store = useSelector(getNearestStoreData);
  const updateScrollButtons = () => {
    if (!emblaApi) return;
    setCanScrollPrev(emblaApi.canScrollPrev());
    setCanScrollNext(emblaApi.canScrollNext());
  };

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on('select', updateScrollButtons);
    updateScrollButtons();
  }, [emblaApi]);

  if (recentlyViewedProducts.length <= 1) {
    return null;
  }

  return (
    <>
      <div className="mb-[6rem] mt-6 w-full ps-6 sm:mx-0 sm:mb-[14rem] sm:pl-6 sm:ps-0 xl:pl-0">
        <div className="mb-4 flex items-center justify-between sm:px-0">
          <p className="font-bentogaThin text-[20px] font-medium text-persianIndigo sm:text-[18px] xl:text-[1.5vw]">
            {t('recently_viewed')}
          </p>
          <div className="flex hidden w-[5rem] items-center justify-between sm:flex">
            <CustomButton
              iconOnly
              btnStyles="z-10 h-[2rem] w-[2rem] !min-w-[2rem] bg-thistle text-[24px]"
              onClickHandling={() => emblaApi.scrollPrev()}
              value={
                locale === 'ar' ? (
                  <MdKeyboardArrowRight />
                ) : (
                  <MdKeyboardArrowLeft />
                )
              }
              isDisabled={!canScrollPrev && !isMobile}
            />
            <CustomButton
              iconOnly
              btnStyles="z-10 h-[2rem] w-[2rem] !min-w-[2rem] bg-thistle text-[24px]"
              onClickHandling={() => emblaApi.scrollNext()}
              value={
                locale === 'ar' ? (
                  <MdKeyboardArrowLeft />
                ) : (
                  <MdKeyboardArrowRight />
                )
              }
              isDisabled={!canScrollNext && !isMobile}
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="embla overflow-hidden" ref={emblaRef}>
            <div
              className={`${
                locale === 'ar' ? 'flex-row-reverse' : 'flex-row'
              } embla__container flex gap-4`}>
              {recentlyViewedProducts.map((product, index) =>
                index === 0 ? null : (
                  <Link
                    key={index}
                    href={`/${locale}/${product?.productSlug}/${product.id}?sid=${store?.id}`}>
                    <div className="embla__slide">
                      <div className="flex h-[185px] w-[161px] cursor-pointer items-center justify-center overflow-hidden rounded-[16px] sm:max-w-[18.75rem] sm:px-0 xl:h-[18.75rem] xl:w-[18.75rem]">
                        <Image
                          src={product.image ? `${product.image}` : product2}
                          width={1200}
                          height={800}
                          alt="product"
                          className="h-full rounded-[16px] sm:h-full xl:max-w-[18.313rem]"
                        />
                      </div>
                      <div className="mt-3 flex items-center justify-between xl:mt-2">
                        <p className="font-secondary text-[12px] font-normal text-persianIndigo xl:text-[0.8vw]">
                          {product.name}
                        </p>
                        {parseInt(product.totalRating) > 0 && (
                          <div className="hidden w-fit items-center sm:flex">
                            {renderStars(product.totalRating)}
                            <span
                              className={`${locale === 'ar' ? 'mr-2' : 'ml-2'} mt-1 font-secondary text-[12px] text-persianIndigo xl:text-[0.8vw]`}>
                              {convertEnglishNumbersToArabic(
                                product.totalRating,
                                locale,
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                      <p className="font-secondary text-[12px] font-normal text-persianIndigo sm:text-[14px] xl:text-[1vw]">
                        +
                        {convertEnglishNumbersToArabic(
                          Number(product.price),
                          locale,
                        )}{' '}
                        {t('sar')}
                      </p>
                    </div>
                  </Link>
                ),
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentlyViewed;
