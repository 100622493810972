'use client';

import {useMediaQuery} from 'react-responsive';
import {WebProductScreen, MobileProductScreen} from '.';

const ProductScreen = ({product}) => {
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});

  return isMobile ? (
    <MobileProductScreen product={product} />
  ) : (
    <WebProductScreen product={product} />
  );
};

export default ProductScreen;
