'use client';

import Image from 'next/image';
import {MdKeyboardArrowRight, MdKeyboardArrowLeft} from 'react-icons/md';
import useEmblaCarousel from 'embla-carousel-react';
import {useLocale} from 'next-intl';
import {product2} from '@/assets';
import {CustomButton} from '../common';
import {useEffect, useState} from 'react';
import {ProductActions} from '.';
import {useMediaQuery} from 'react-responsive';

const ImageCarousel = ({selectedVariant, product}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const locale = useLocale();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});

  const getImages = () => {
    if (selectedVariant?.attributes?.images?.length > 0) {
      return selectedVariant.attributes.images;
    }
    if (selectedVariant?.productImages?.length > 0) {
      return selectedVariant.productImages.map(
        img => img.attributes.original_url,
      );
    }
    return [];
  };

  const images = getImages();

  const handleThumbnailClick = index => {
    setSelectedImageIndex(index);
  };

  const goNext = () => {
    if (emblaApi) {
      emblaApi.scrollNext();
      setSelectedImageIndex(prevIndex =>
        Math.min(prevIndex + 1, images.length - 1),
      );
    }
  };

  const goPrevious = () => {
    if (emblaApi) {
      emblaApi.scrollPrev();
      setSelectedImageIndex(prevIndex => Math.max(prevIndex - 1, 0));
    }
  };

  useEffect(() => {
    setSelectedImageIndex(0);
  }, [selectedVariant]);

  useEffect(() => {
    if (emblaApi) {
      const updateScrollButtons = () => {
        setCanScrollNext(emblaApi.canScrollNext());
        setCanScrollPrev(emblaApi.canScrollPrev());
      };

      emblaApi.on('select', updateScrollButtons);
      emblaApi.on('reInit', updateScrollButtons);

      updateScrollButtons();

      return () => {
        emblaApi.off('select', updateScrollButtons);
        emblaApi.off('reInit', updateScrollButtons);
      };
    }
  }, [emblaApi]);

  const renderThumbnail = (image, index) => (
    <div className="embla__slide" key={index}>
      <div
        className={`flex h-[7.6rem] w-[7.3rem] cursor-pointer items-center justify-center rounded-[8px] ${
          selectedImageIndex === index ? 'border-2 border-royalPurple' : ''
        }`}
        onClick={() => handleThumbnailClick(index)}>
        <Image
          src={image}
          width={600}
          height={400}
          alt="product view"
          className="h-[7.188rem] w-[6.875rem] rounded-[8px] object-fill"
        />
      </div>
    </div>
  );

  return (
    <div className="flex flex-col">
      <div className="relative">
        {isMobile ? (
          <div className="embla w-[90%] overflow-hidden" ref={emblaRef}></div>
        ) : (
          <div className="relative sm:h-[24.375rem] sm:w-[23.125rem]">
            <ProductActions
              product={product}
              selectedVariant={selectedVariant}
              selectedImage={images[selectedImageIndex]}
            />
            <Image
              src={images[selectedImageIndex] || product2}
              width={800}
              height={800}
              alt="product image"
              className="mb-2 h-[20rem] object-fill px-0 sm:mb-4 sm:h-[24.375rem] sm:w-[23.125rem] sm:rounded-[8px]"
            />
          </div>
        )}
      </div>

      <div className="flex items-center justify-between gap-2 px-0 sm:w-[25.8rem] sm:gap-[16px]">
        {canScrollPrev && (
          <CustomButton
            iconOnly
            onClickHandling={locale === 'ar' ? goNext : goPrevious}
            btnStyles="z-10 !h-[2rem] w-[2rem] !min-w-[2rem] bg-thistle text-[24px]"
            value={
              locale === 'ar' ? (
                <MdKeyboardArrowRight />
              ) : (
                <MdKeyboardArrowLeft />
              )
            }
          />
        )}
        <div className="embla w-[90%] overflow-hidden" ref={emblaRef}>
          <div
            className={`${
              locale === 'ar' ? 'flex-row-reverse' : 'flex-row'
            } embla__container flex ${!canScrollNext && !canScrollPrev ? 'justify-center' : ''} gap-[10px]`}>
            {images.map(renderThumbnail)}
          </div>
        </div>
        {canScrollNext && (
          <CustomButton
            iconOnly
            onClickHandling={locale === 'ar' ? goPrevious : goNext}
            btnStyles="z-10 !h-[2rem] w-[2rem] !min-w-[2rem] bg-thistle text-[24px]"
            value={
              locale === 'ar' ? (
                <MdKeyboardArrowLeft />
              ) : (
                <MdKeyboardArrowRight />
              )
            }
          />
        )}
      </div>
    </div>
  );
};

export default ImageCarousel;
