'use client';

import {MdKeyboardArrowRight, MdKeyboardArrowLeft} from 'react-icons/md';
import useEmblaCarousel from 'embla-carousel-react';
import Image from 'next/image';
import {
  convertEnglishNumbersToArabic,
  getImageSrc,
  renderStars,
} from '@/common/helpers';
import {CustomButton} from '../common';
import {useRouter} from '@/hooks';
import {getNearestStoreData, getRecentlyViewedProducts} from '@/selectors';
import {useSelector} from 'react-redux';
import {useMemo, useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';

const RelatedProducts = ({t, locale, products, category}) => {
  const router = useRouter();
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});
  const [emblaRef, emblaApi] = useEmblaCarousel({loop: false});
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const recentlyViewedProducts = useSelector(getRecentlyViewedProducts);
  const store = useSelector(getNearestStoreData);

  const isThereOnlyOneProductOrNone = useMemo(
    () => recentlyViewedProducts.length <= 1,
    [recentlyViewedProducts],
  );

  const onHandlingNavigatingToProduct = path => {
    router.push(path);
  };

  const updateScrollButtons = () => {
    if (!emblaApi) return;
    setCanScrollPrev(emblaApi.canScrollPrev());
    setCanScrollNext(emblaApi.canScrollNext());
  };

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on('select', updateScrollButtons);
    updateScrollButtons();
  }, [emblaApi]);

  if (products?.data?.length === 0) return;

  return (
    <div
      className={`${isThereOnlyOneProductOrNone ? 'mb-[6rem] sm:mb-[11rem]' : 'sm:mb-[4rem] xl:mb-[8rem]'} mt-[3rem] w-full ps-6 sm:mx-0 sm:mt-0 sm:pl-6 sm:ps-0 xl:pl-0`}>
      <div className="mb-4 flex items-center justify-between sm:px-0">
        <p className="font-bentogaThin text-[20px] font-medium text-persianIndigo sm:text-[18px] xl:text-[1.5vw]">
          {t('related_products')}
        </p>
        <div className="flex hidden w-[5rem] items-center justify-between sm:flex">
          <CustomButton
            iconOnly
            btnStyles="z-10 h-[2rem] w-[2rem] !min-w-[2rem] bg-thistle text-[24px]"
            onClickHandling={() => emblaApi.scrollPrev()}
            value={
              locale === 'ar' ? (
                <MdKeyboardArrowRight />
              ) : (
                <MdKeyboardArrowLeft />
              )
            }
            isDisabled={!canScrollPrev && !isMobile}
          />
          <CustomButton
            iconOnly
            btnStyles="z-10 h-[2rem] w-[2rem] !min-w-[2rem] bg-thistle text-[24px]"
            onClickHandling={() => emblaApi.scrollNext()}
            value={
              locale === 'ar' ? (
                <MdKeyboardArrowLeft />
              ) : (
                <MdKeyboardArrowRight />
              )
            }
            isDisabled={!canScrollNext && !isMobile}
          />
        </div>
      </div>
      <div className="flex items-center justify-between sm:mb-[4rem] xl:mb-6">
        <div className="embla overflow-hidden" ref={emblaRef}>
          <div
            className={`${
              locale === 'ar' ? 'flex-row-reverse' : 'flex-row'
            } embla__container flex gap-4`}>
            {products?.data?.map((product, index) => {
              if (
                !product?.relationships?.images?.data.length > 0 ||
                !product?.attributes?.in_stock
              ) {
                return null;
              }
              const imageSrc = getImageSrc(product, products);
              return (
                <div
                  className="embla__slide"
                  key={index}
                  onClick={() =>
                    onHandlingNavigatingToProduct(
                      `/${category}/${product?.attributes?.slug}/${product.id}?sid=${store?.id}`,
                    )
                  }>
                  <div className="flex h-[185px] w-[161px] cursor-pointer items-center justify-center overflow-hidden rounded-[16px] sm:max-w-[18.75rem] sm:px-0 xl:h-[18.75rem] xl:w-[18.75rem]">
                    <Image
                      src={imageSrc}
                      width={1200}
                      height={800}
                      alt="related product"
                      className="h-full rounded-[16px] sm:h-full xl:max-w-[18.313rem]"
                    />
                  </div>
                  <div className="mt-2 flex items-center justify-between">
                    <p className="font-secondary text-[12px] font-normal text-persianIndigo xl:text-[0.8vw]">
                      {product.attributes.name}
                    </p>
                    {parseInt(product.attributes.total_rating) > 0 && (
                      <div className="hidden w-fit items-center sm:flex">
                        {renderStars(product.attributes?.total_rating)}
                        <span
                          className={`${locale === 'ar' ? 'mr-2' : 'ml-2'} mt-1 font-secondary text-[12px] text-persianIndigo xl:text-[0.8vw]`}>
                          {convertEnglishNumbersToArabic(
                            product.attributes?.total_rating,
                            locale,
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                  <p className="font-secondary text-[12px] font-normal text-persianIndigo sm:ps-0 sm:text-[14px] xl:text-[1vw]">
                    +
                    {convertEnglishNumbersToArabic(
                      Number(product.attributes.price),
                      locale,
                    )}{' '}
                    {t('sar')}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {!isThereOnlyOneProductOrNone && (
        <hr className="mb-8 mr-6 hidden border-0 border-t border-thistle sm:mr-0 sm:block sm:w-[65%] xl:hidden" />
      )}
    </div>
  );
};

export default RelatedProducts;
