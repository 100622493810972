'use client';

import {appleLogo, tamaraLogo} from '@/assets';
import {commonDisableCachingParameters} from '@/common';
import {useGetPaymentMethodsQuery} from '@/services';
import {useTranslations} from 'next-intl';
import Image from 'next/image';
import React from 'react';
import {HiCreditCard} from 'react-icons/hi2';

const WaysToPay = () => {
  const t = useTranslations();

  const {data: paymentMethods} = useGetPaymentMethodsQuery(
    commonDisableCachingParameters,
  );
  return (
    <div className="mb-8 flex items-center justify-between rounded-[11px] border-1 border-persianIndigo px-4 py-2">
      <p className="font-secondary text-[16px] text-persianIndigo sm:text-[20px]">
        {t('ways_to_pay')}
      </p>
      <div className="flex items-center gap-2">
        {(paymentMethods?.guestPaymentMethod ||
          paymentMethods?.creditPaymentMethod) && (
          <div className="flex h-[7.5vw] w-[9vw] items-center justify-center bg-black sm:h-[2.5vw] sm:w-[3vw]">
            <HiCreditCard color="white" size={30} />
          </div>
        )}

        {paymentMethods?.applePaymentMethod && (
          <div className="h-[7.5vw] w-[9vw] sm:h-[2.5vw] sm:w-[3vw]">
            <Image
              src={appleLogo}
              width={76}
              height={56}
              alt="apple Logo"
              className="h-[7.5vw] w-[9vw] sm:h-[2.5vw] sm:w-[3vw]"
            />
          </div>
        )}
        {paymentMethods?.tamaraPaymentMethod && (
          <div className="h-[7.5vw] w-[9vw] sm:h-[2.5vw] sm:w-[3vw]">
            <Image
              src={tamaraLogo}
              width={76}
              height={56}
              alt="tamara Logo"
              className="h-[7.5vw] w-[9vw] sm:h-[2.5vw] sm:w-[3vw]"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WaysToPay;
