'use client';

import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import {FaCheck} from 'react-icons/fa6';
import useEmblaCarousel from 'embla-carousel-react';
import {useLocale, useTranslations} from 'next-intl';
import {useEffect, useMemo, useState} from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@heroui/react';
import Image from 'next/image';
import {CustomButton} from '../common';
import {setGiftCardsCarouselInedx, setSelectedImageURL} from '@/slices';
import {useDispatch, useSelector} from 'react-redux';
import {getGiftCardsCarouselIndex, getSelectedImageURL} from '@/selectors';
import {useMediaQuery} from 'react-responsive';

const GiftCardImageModal = ({
  onImageSelect,
  cardDesigns,
  isCheckout,
  onHandlingChoosenGiftCard,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedImage = useSelector(getSelectedImageURL);
  const carouselIndex = useSelector(getGiftCardsCarouselIndex);

  const locale = useLocale();
  const t = useTranslations();
  const dispatch = useDispatch();
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const isTablet = useMediaQuery({query: '(max-width: 1024px)'});
  const isExtraLarge = useMediaQuery({query: '(min-width: 1336px)'});

  const screenWidth = typeof window !== 'undefined' ? window.innerWidth : 1366;
  const imageWidth = (1336 / screenWidth) * 90;

  const designUrls = useMemo(
    () => cardDesigns?.designUrls || [],
    [cardDesigns],
  );
  const cardsWithPrices = useMemo(
    () => cardDesigns?.cardsWithPrices || [],
    [cardDesigns],
  );

  useEffect(() => {
    if (!selectedImage) {
      dispatch(setSelectedImageURL(cardDesigns?.freeCardUrl));
    }
    onHandlingChoosenGiftCard(cardDesigns?.freeCardId);
  }, [cardDesigns]);

  useEffect(() => {
    if (emblaApi && carouselIndex !== null) {
      emblaApi.scrollTo(carouselIndex, true);
    }
  }, [emblaApi]);

  const handleOpenModal = () => {
    setIsOpen(true);
    dispatch(setSelectedImageURL(designUrls[carouselIndex]));
    dispatch(setGiftCardsCarouselInedx(carouselIndex));
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onHandlingEndingSpaces = (index, length) => {
    if (index === 0) {
      return 'ml-[2.063rem] sm:ml-0';
    } else if (index === length - 1) {
      return 'mr-[2.063rem] sm:mr-0';
    }
  };

  const handleContinue = () => {
    setIsOpen(false);
    onImageSelect(designUrls[carouselIndex]);
  };

  const handleImageClick = index => {
    dispatch(setSelectedImageURL(designUrls[index]));
    dispatch(setGiftCardsCarouselInedx(index));
    emblaApi.scrollTo(index, false);

    const selectedCard = cardsWithPrices[index];
    if (selectedCard) {
      onHandlingChoosenGiftCard(selectedCard.variants?.[0]);
    }
  };

  return (
    <>
      <div
        className={`flex flex-row-reverse items-center justify-between bg-mediumWhite sm:block xl:mb-0 xl:w-1/2`}>
        <p className="mb-2 block w-1/2 text-center font-secondary text-[14px] font-normal text-persianIndigo sm:hidden sm:w-full">
          {t('choose_your_design')}
        </p>
        {selectedImage && (
          <div
            className={`${isCheckout ? 'h-[400px] sm:h-[287px] xl:h-[13.8rem]' : 'h-[230px] sm:h-[287px] xl:h-[18.5rem]'} mb-3 flex w-1/2 justify-center rounded-[11px] border-2 border-dashed border-crayola p-2 sm:w-full`}>
            <Image
              src={selectedImage}
              width={isCheckout && !isTablet ? 166.5 : 220}
              height={800}
              className={`${isCheckout ? `xl:h-[12.6rem]` : `xl:min-h-[16.5rem]`} ${isExtraLarge ? `w-[${imageWidth}%]` : 'w-full'} h-[210px] cursor-pointer object-fill sm:h-[267px]`}
              onClick={() => handleOpenModal(0)}
              alt="gift card"
            />
          </div>
        )}
        <p className="hidden text-center font-secondary font-normal text-persianIndigo sm:block sm:text-[14px] xl:text-[1vw]">
          {t('choose_your_design')}
        </p>
      </div>
      <Modal
        isOpen={isOpen}
        size="5xl"
        className="mx-6 h-[35.938rem] w-[23.813rem] p-8 sm:mx-0 sm:h-[22.813rem] sm:w-[46.438rem] xl:h-[28rem] xl:w-[72.438rem]"
        closeButton={
          <CustomButton
            btnType="tertiary"
            value={'X'}
            onClickHandling={onClose}
          />
        }
        classNames={{
          closeButton:
            '!text-[22px] !text-persianIndigo hover:!bg-thistle font-main !p-0 !rounded-md mt-4 hidden sm:block',
          wrapper: 'items-center',
        }}
        onClose={onClose}>
        <ModalContent>
          <ModalHeader className="mb-[6rem] justify-center !p-0 text-center font-main font-medium text-persianIndigo sm:mb-4 sm:text-[18px] xl:text-[24px]">
            {t('choose_your_card')}
          </ModalHeader>
          <ModalBody className="flex h-[100%] justify-between !p-0">
            <div className="flex w-full items-center justify-between">
              <Button
                isIconOnly
                className="z-40 hidden !h-[2rem] w-[2rem] !min-w-[2rem] cursor-pointer rounded-full border-none bg-thistle text-[24px] text-white sm:inline-flex"
                onPress={() =>
                  locale === 'ar'
                    ? emblaApi.scrollNext()
                    : emblaApi.scrollPrev()
                }>
                {locale === 'ar' ? (
                  <MdKeyboardArrowRight />
                ) : (
                  <MdKeyboardArrowLeft />
                )}{' '}
              </Button>
              <div className="embla mx-2 w-full overflow-hidden" ref={emblaRef}>
                <div
                  className={`${locale === 'ar' ? 'flex-row-reverse' : 'flex-row'} embla__container flex gap-4`}>
                  {cardsWithPrices?.map((image, index) => (
                    <div
                      className="embla__slide"
                      key={index}
                      onClick={() => handleImageClick(index)}>
                      <div
                        className={`${onHandlingEndingSpaces(index, cardsWithPrices.length)} flex h-[12.188rem] w-[14.75rem] cursor-pointer items-center justify-center border-4 sm:h-[9.625rem] sm:w-[11.688rem] xl:h-[15.188rem] xl:w-[18.438rem] ${image?.original_url === selectedImage ? 'border-persianIndigo' : 'border-transparent'} relative`}>
                        <Image
                          src={image?.original_url}
                          alt="card"
                          className="h-[11rem] object-cover sm:h-[9rem] xl:h-[13rem]"
                          width={800}
                          height={800}
                        />
                        {image?.original_url === selectedImage && (
                          <div className="absolute bottom-2 right-2 rounded-full bg-royalPurple p-2 xl:p-2">
                            <FaCheck className="text-white sm:text-[22px] xl:text-[30px]" />
                          </div>
                        )}
                      </div>
                      <p
                        className={`${onHandlingEndingSpaces(index, cardsWithPrices.length)} mt-2 text-center font-main text-[16px] font-normal text-persianIndigo`}>
                        {image?.price} {t('sar')}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <Button
                isIconOnly
                className="z-40 hidden !h-[2rem] w-[2rem] !min-w-[2rem] cursor-pointer rounded-full border-none bg-thistle text-[24px] text-white sm:inline-flex"
                onPress={() =>
                  locale === 'ar'
                    ? emblaApi.scrollPrev()
                    : emblaApi.scrollNext()
                }>
                {locale === 'ar' ? (
                  <MdKeyboardArrowLeft />
                ) : (
                  <MdKeyboardArrowRight />
                )}
              </Button>
            </div>
            <div className="flex justify-center gap-4">
              <Button
                isIconOnly
                className="z-40 !h-[2rem] w-[2rem] !min-w-[2rem] cursor-pointer rounded-full border-none bg-thistle text-[24px] text-white sm:hidden"
                onPress={() =>
                  locale === 'ar'
                    ? emblaApi.scrollNext()
                    : emblaApi.scrollPrev()
                }>
                {locale === 'ar' ? (
                  <MdKeyboardArrowRight />
                ) : (
                  <MdKeyboardArrowLeft />
                )}{' '}
              </Button>
              <Button
                isIconOnly
                className="z-40 !h-[2rem] w-[2rem] !min-w-[2rem] cursor-pointer rounded-full border-none bg-thistle text-[24px] text-white sm:hidden"
                onPress={() =>
                  locale === 'ar'
                    ? emblaApi.scrollPrev()
                    : emblaApi.scrollNext()
                }>
                {locale === 'ar' ? (
                  <MdKeyboardArrowLeft />
                ) : (
                  <MdKeyboardArrowRight />
                )}
              </Button>
            </div>

            <CustomButton
              onClickHandling={handleContinue}
              value={t('continue')}
              btnStyles="w-[20rem] mx-auto mt-4"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GiftCardImageModal;
