'use client';

import {useEffect, useMemo, useState} from 'react';
import {GiftCardImageModal, LinkModal} from '.';
import GiftCardForm from './GiftCardForm';
import VideoRecorderModal from './VideoRecorderModal';
import GiftCardPreview from './GiftCardPreview';
import {useDispatch, useSelector} from 'react-redux';
import {getCart, getSelectedImageURL} from '@/selectors';
import {useTranslations} from 'next-intl';
import {setSelectedImageURL} from '@/slices';

const GiftCard = ({
  setWrittenCardData,
  onHandlingChoosenGiftCard,
  cardDesigns,
  isGiftCardShown,
  setIsGiftCardShown,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
  const selectedImage = useSelector(getSelectedImageURL);
  const [formData, setFormData] = useState({
    to: '',
    from: '',
    message: '',
  });
  const [link, setLink] = useState('');
  const [writtenImage, setWrittenImage] = useState(null);
  const [video, setVideo] = useState(null);

  const order = useSelector(getCart);

  const handleLinkChange = e => {
    setLink(e.target.value);
  };

  useEffect(() => {
    dispatch(setSelectedImageURL(cardDesigns?.freeCardUrl));
  }, [cardDesigns]);

  const handleRecordVideoClick = () => {
    setIsVideoModalOpen(true);
  };

  const handlePasteLinkClick = () => {
    setIsLinkModalOpen(true);
  };

  const handleImageSelect = image => {
    dispatch(setSelectedImageURL(image));
  };

  const handleFormChange = data => {
    setFormData(data);
  };

  const uploadWrittenCard = () => {
    const formdata = new FormData();
    if (video) {
      formdata.append('video', video);
    }
    formdata.append('order_id', order?.id);
    formdata.append('message', formData?.message);
    formdata.append('from', formData?.from);
    formdata.append('to', formData?.to);
    formdata.append('media_link', link);
    formdata.append('image', writtenImage);

    setWrittenCardData(formdata);
  };

  useEffect(() => {
    uploadWrittenCard();
  }, [order, formData, link, writtenImage, video]);

  useEffect(() => {
    setIsGiftCardShown(false);
  }, [formData]);

  const isToShowWrittenCard = useMemo(
    () =>
      selectedImage &&
      (formData.to || formData.message || formData.from) &&
      isGiftCardShown,
    [selectedImage, formData, isGiftCardShown],
  );

  return (
    cardDesigns?.cardsWithPrices?.length > 0 && (
      <>
        {/* <p className="mx-6 mb-4 font-secondary text-persianIndigo sm:mx-0 sm:pr-6 sm:text-[14px] xl:pr-0 xl:text-[1vw]">
          {t('gift_card_and_message')}
        </p> */}
        <div className="mx-0 px-6 sm:px-0 sm:pr-6 xl:pr-0">
          <div className="mb-8 flex w-full flex-col gap-4 sm:mb-6 xl:flex-row">
            {isToShowWrittenCard ? (
              <GiftCardPreview
                image={selectedImage}
                setImageURL={setWrittenImage}
                formData={{
                  to: formData.to,
                  message: formData.message,
                  from: formData.from,
                }}
                urlExists={link || video}
              />
            ) : (
              <GiftCardImageModal
                onImageSelect={handleImageSelect}
                cardDesigns={cardDesigns}
                onHandlingChoosenGiftCard={onHandlingChoosenGiftCard}
              />
            )}
            <GiftCardForm
              handleRecordVideoClick={handleRecordVideoClick}
              handlePasteLinkClick={handlePasteLinkClick}
              formData={formData}
              onFormChange={handleFormChange}
              setIsGiftCardShown={setIsGiftCardShown}
            />
          </div>
          <VideoRecorderModal
            isVideoModalOpen={isVideoModalOpen}
            setIsVideoModalOpen={setIsVideoModalOpen}
            setVideo={setVideo}
          />
          <LinkModal
            isLinkModalOpen={isLinkModalOpen}
            setIsLinkModalOpen={setIsLinkModalOpen}
            link={link}
            handleLinkChange={handleLinkChange}
          />
        </div>
      </>
    )
  );
};

export default GiftCard;
