'use client';

import {
  commonDisableCachingParameters,
  convertEnglishNumbersToArabic,
  renderStars,
  showErrorToast,
} from '@/common/helpers';
import {useApplyCartActions, UseViewPortHeight} from '@/hooks';
import {getCart, getNearestStoreData} from '@/selectors';
import {
  useGetAddOnsQuery,
  useGetAllCategoriesQuery,
  useGetCardDesignsQuery,
} from '@/services';
import {useLocale} from 'next-intl';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {GiftCard, Reviews, WaysToPay} from '.';
import {CustomButton} from '../common';
import AddOns from './AddOns';

const ProductDetails = ({product, t, selectedVariant, setSelectedVariant}) => {
  const locale = useLocale();
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});

  const store = useSelector(getNearestStoreData);
  const {data: allCategories, isError: getAllCategoriesError} =
    useGetAllCategoriesQuery(
      {include: 'image', locale, per_page: 100},
      commonDisableCachingParameters,
    );

  const addonsCategory = useMemo(() => {
    return allCategories?.data?.find(category => category.attributes.addons);
  }, [allCategories]);

  const {data: addOns, error: getAddOnsError} = useGetAddOnsQuery(
    {
      locale,
      'filter[branches]': store?.id,
      per_page: 100,
      'filter[taxons]': addonsCategory?.id,
    },
    commonDisableCachingParameters,
  );
  const {data: cardDesigns, error: getCardDesignsError} =
    useGetCardDesignsQuery(
      {locale, 'filter[branches]': store?.id},
      commonDisableCachingParameters,
    );
  const [modalVisible, setModalVisible] = useState(false);
  const [writtenCardData, setWrittenCardData] = useState(null);
  const [selectedAddons, setSelectedAddons] = useState(null);
  const [chosenGiftCard, setChoosenGiftCard] = useState();
  const [isGiftCardShown, setIsGiftCardShown] = useState(false);

  const onHandlingChoosenGiftCard = cardId => {
    setChoosenGiftCard(cardId);
  };

  const cart = useSelector(getCart);
  useEffect(() => {
    setSelectedVariant(product?.productVariants?.[0]);
  }, [product]);

  const onHandlingSelectedAddons = addons => {
    setSelectedAddons(addons);
  };

  const {applyCartAction, isAddingToCartLoading} = useApplyCartActions();

  const handleAddingToCart = async () => {
    try {
      const productAndGiftCardPromises = [];

      if (selectedVariant?.id) {
        productAndGiftCardPromises.push(
          applyCartAction('add', selectedVariant, 1),
        );
      }

      if (
        chosenGiftCard?.id &&
        !cart?.hasWrittenCard &&
        writtenCardData?.get('message') &&
        isGiftCardShown
      ) {
        productAndGiftCardPromises.push(
          applyCartAction('add', chosenGiftCard, 1),
        );
      }

      await Promise.all(productAndGiftCardPromises);

      if (selectedAddons?.length > 0) {
        await selectedAddons.reduce(async (previousPromise, addon) => {
          await previousPromise;
          await applyCartAction('add', addon, 1);
        }, Promise.resolve());
      }
    } catch (error) {
      showErrorToast(t('failed_to_add_items_to_cart'));
    }

    if (writtenCardData?.get('message')) {
      const requestOptions = {
        method: 'POST',
        body: writtenCardData,
        redirect: 'follow',
      };

      fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/api/v2/storefront/written_cards`,
        requestOptions,
      )
        .then(response => response.text())
        .catch(error => showErrorToast(t('video_upload_failed')));
    }
  };

  if (getAddOnsError) {
    throw new Error('Failed to load add-ons.');
  }

  return (
    <>
      <div className="products-container relative hidden sm:flex sm:items-center sm:justify-between sm:pr-6 xl:pr-0">
        <div>
          <p className="font-main font-medium text-persianIndigo sm:text-[20px] xl:text-[32px]">
            {convertEnglishNumbersToArabic(
              Number(product?.productInfo?.price),
              locale,
            )}{' '}
            {t('sar')}{' '}
          </p>
          <p className="font-regular relative bottom-[8px] font-secondary text-[12px] text-persianIndigo">
            {t('price_include_tax')}
          </p>
          <p className="mb-4 font-secondary font-thin text-persianIndigo sm:text-[16px] xl:text-[1vw]">
            {product?.productInfo?.name}
          </p>
        </div>
        {product?.productInfo?.totalRating > 0 && (
          <div className="flex flex-col items-end">
            <div className="flex w-fit items-center py-2 xl:mb-2">
              {renderStars(product?.productInfo?.totalRating, 4)}
              <span className="ml-2 text-persianIndigo">
                {product?.productInfo?.totalRating}
              </span>
            </div>
            <button
              onClick={() => setModalVisible(true)}
              className="font-secondary font-normal text-persianIndigo underline sm:text-[14px] xl:text-[1vw]">
              {parseInt(product?.totalReviews) === 0
                ? t('no')
                : product?.totalReviews}{' '}
              {parseInt(product?.totalReviews) === 1
                ? t('review')
                : t('reviews')}
            </button>
          </div>
        )}
      </div>

      {!isMobile && (
        <div className="mb-4 w-full justify-end px-6 sm:static sm:flex sm:px-0 sm:pr-6 xl:pr-0">
          <CustomButton
            value={t('add_to_cart')}
            isLoading={isAddingToCartLoading}
            onClickHandling={handleAddingToCart}
            btnStyles="w-full h-[3.313rem] bg-persianIndigo font-secondary font-thin"
          />
        </div>
      )}
      <div className="bg-mediumWhite">
        <GiftCard
          setWrittenCardData={setWrittenCardData}
          onHandlingChoosenGiftCard={onHandlingChoosenGiftCard}
          cardDesigns={cardDesigns}
          isGiftCardShown={isGiftCardShown}
          setIsGiftCardShown={setIsGiftCardShown}
        />
      </div>
      <WaysToPay />
      <AddOns
        addOns={addOns}
        t={t}
        onHandlingSelectedAddons={onHandlingSelectedAddons}
      />

      {isMobile && (
        <div id="stop-point" className="relative top-[20px] pb-[30px]"></div>
      )}

      <Reviews
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        product={product}
      />
    </>
  );
};

export default ProductDetails;
